import "./login.scss"
import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { baseUrl } from "../config.js/baseUrl";

const Login = () => {
  let navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({ email: '', password: '' });

  const validateForm = () => {
    let valid = true;
    let errors = {};

    if (!email) {
      errors.email = 'Email is required';
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Email is invalid';
      valid = false;
    }

    if (!password) {
      errors.password = 'Password is required';
      valid = false;
    }

    setErrors(errors);
    return valid;
  };

  async function login(e) {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    let item = { email, password };
    try {
      let result = await axios.post(baseUrl + "/auth/adminLogin", item);
      if (!result.data.token) {
        alert('Login failed');
      } else {
        localStorage.setItem('token', result.data.token);
        navigate("/dashboard");
      }
    } catch (error) {
      alert('Login failed');
    }
  }

  return (
    <div className="login-container">
      <div className="login-form">
        <h2>Welcome, Log into your account</h2>
        <form onSubmit={login}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input 
              type="text" 
              name="email" 
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {errors.email && <div className="error" style={{color:"red"}}>{errors.email}</div>}
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input 
              type="password" 
              name="password" 
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {errors.password && <div className="error" style={{color:"red"}}>{errors.password}</div>}
          </div>
          <button type="submit">Login</button>
        </form>
      </div>
    </div>
  )
}

export default Login;
