import "./new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useParams, useNavigate } from "react-router-dom";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { ToastContainer, toast } from 'react-toastify';
import { useState } from "react";
import axios from "axios";
import { baseUrl } from "../config.js/baseUrl";

const New = ({ title }) => {
  const [file, setFile] = useState(null);
  let navigate = useNavigate();
  const [formData, setFormData] = useState({
    studentDetails: {},
    parentDetails: {},
    courseDetails: {},
    admissionFees: '',
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    const [section, key] = name.split('.');

    if (key) {
      setFormData((prevData) => ({
        ...prevData,
        [section]: {
          ...prevData[section],
          [key]: value,
        },
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [section]: value,
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.studentDetails.firstName) newErrors.firstName = "First Name is required";
    if (!formData.studentDetails.lastName) newErrors.lastName = "Last Name is required";
    if (!formData.studentDetails.gender) newErrors.gender = "Gender is required";
    if (!formData.studentDetails.dob) newErrors.dob = "Date of Birth is required";
    if (!formData.studentDetails.email) newErrors.email = "Email is required";
    if (!formData.studentDetails.studentId) newErrors.studentId = "Student ID is required";
    if (!formData.studentDetails.rollNumber) newErrors.rollNumber = "Roll Number is required";
    if (!formData.studentDetails.mobileNumber) newErrors.mobileNumber = "Mobile Number is required";
    if (!formData.studentDetails.address) newErrors.address = "Address is required";
    if (!formData.parentDetails.firstName) newErrors.parentFirstName = "Parent First Name is required";
    if (!formData.parentDetails.lastName) newErrors.parentLastName = "Parent Last Name is required";
    if (!formData.parentDetails.mobileNumber) newErrors.parentMobileNumber = "Parent Mobile Number is required";
    if (!formData.courseDetails.courseName) newErrors.courseName = "Course Name is required";
    if (!formData.courseDetails.session) newErrors.session = "Session is required";
    if (!formData.admissionFees) newErrors.admissionFees = "Admission Fees is required";
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      toast.error('Please fill in all required fields.');
      return;
    }
    const data = new FormData();

    if (file) {
      data.append('image', file);
    }

    Object.keys(formData).forEach((section) => {
      if (typeof formData[section] === 'object') {
        Object.keys(formData[section]).forEach((key) => {
          data.append(`${section}[${key}]`, formData[section][key]);
        });
      } else {
        data.append(section, formData[section]);
      }
    });

    try {
      const response = await axios.post(baseUrl + '/addNewStudent', data);
      toast.success('Student added successfully!', { autoClose: 600 });
      setTimeout(() => {
        navigate("/users");
      }, 1000);
    } catch (error) {
      const errorMessage = error.response && error.response.data && error.response.data.message
        ? error.response.data.message
        : "An error occurred. Please try again.";
      toast.error(errorMessage, { autoClose: 600 });
    }
  };

  const inputs = [
    {
      section: "studentDetails",
      label: "First Name",
      name: "firstName",
      type: "text",
      placeholder: "Enter first name",
    },
    {
      section: "studentDetails",
      label: "Last Name",
      name: "lastName",
      type: "text",
      placeholder: "Enter last name",
    },
    {
      section: "studentDetails",
      label: "Gender",
      name: "gender",
      type: "select",
      options: ["Male", "Female", "Other"],  // Added options for select dropdown
    },
    {
      section: "studentDetails",
      label: "Date of Birth",
      name: "dob",
      type: "date",
    },
    {
      section: "studentDetails",
      label: "Email",
      name: "email",
      type: "email",
      placeholder: "Enter email id",
    },
    {
      section: "studentDetails",
      label: "studentId",
      name: "studentId",
      type: "text",
      placeholder: "Enter student id"
    },
    {
      section: "studentDetails",
      label: "RollNumber",
      name: "rollNumber",
      type: "text",
      placeholder: "Enter roll number",
    },
    {
      section: "studentDetails",
      label: "Mobile Number",
      name: "mobileNumber",
      type: "text",
      placeholder: "Enter mobile number",
    },
    {
      section: "studentDetails",
      label: "Address",
      name: "address",
      type: "text",
      placeholder: "Enter address",
    },
    {
      section: "parentDetails",
      label: "Parent First Name",
      name: "firstName",
      type: "text",
      placeholder: "Enter parent first name ",
    },
    {
      section: "parentDetails",
      label: "Parent Last Name",
      name: "lastName",
      type: "text",
      placeholder: "Enter parent last name",
    },
    {
      section: "parentDetails",
      label: "Parent Mobile Number",
      name: "mobileNumber",
      type: "text",
      placeholder: "Enter parent mobile number",
    },
    {
      section: "courseDetails",
      label: "Course Name",
      name: "courseName",
      type: "text",
      placeholder: "Enter course name",
    },
    {
      section: "courseDetails",
      label: "Session",
      name: "session",
      type: "text",
      placeholder: "Enter session",
    },
    {
      label: "Admission Fees",
      name: "admissionFees",
      type: "number",
      placeholder: "Enter admission fees",
    },
  ];

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="left">
            <img
              src={
                file
                  ? URL.createObjectURL(file)
                  : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
              }
              alt=""
            />
          </div>
          <div className="right">
            <form onSubmit={handleSubmit}>
              <div className="formInput">
                <label htmlFor="file">
                  Upload image: <DriveFolderUploadOutlinedIcon className="icon" />
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={(e) => setFile(e.target.files[0])}
                  style={{ display: "none" }}
                />
              </div>

              {inputs.map((input, index) => (
                <div className="formInput" key={index}>
                  <label>{input.label}</label>
                  {input.type === "select" ? (
                    <select
                      name={`${input.section}.${input.name}`}
                      onChange={handleChange}
                    >
                      <option value="">Select {input.label}</option>
                      {input.options.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <input
                      type={input.type}
                      name={input.section ? `${input.section}.${input.name}` : input.name}
                      placeholder={input.placeholder}
                      onChange={handleChange}
                    />
                  )}
                  {errors[input.name] && <span className="error" style={{ color: "red" }}>{errors[input.name]}</span>}
                </div>
              ))}

              <button type="submit">Send</button>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default New;
